//import axios from 'axios';
import qs from 'qs';
import httpAxios from './http-common';
import errorHandler from './errorHandler';

const LOG_REQUESTS = false;
/*
const LOG_REQUESTS = process.env.VUE_APP_SHOW_DEBUG === 'true';

const CancelToken = axios.CancelToken;
let cancelAvailability;
*/
function logResponse(response, key) {
  if (LOG_REQUESTS) {
    if (key) {
      if (console.groupCollapsed) {
        console.groupCollapsed(key);
      }
      console.log(response);
      //console.trace();
      if (console.groupEnd) {
        console.groupEnd(key);
      }
      //console.log(JSON.stringify(response));
    } else {
      console.log(response);
    }
  }
}

export default {
  //axios: httpAxios,

  showLogs: LOG_REQUESTS,

  /**
   * Get Menu Items
   * @param {*} success success callback
   * @param {*} error error callback
   */
  getMenu(id, success, error) {
    const methodName = 'getMenu';

    httpAxios.get(id ? `get_menu_projects/${id}` : 'get_menu_projects/')
      .then(({ data }) => {
        logResponse(data, data.error ? `${methodName}: ERROR` : methodName);

        if (data.error) {
          error(errorHandler.processError(data.message));
        } else {
          const response = data.data;
          success(response);
        }
      })
      .catch((ex) => {
        logResponse(ex, `${methodName}: EXCEPTION`);
        error(errorHandler.processError(ex));
      });
  },

  /**
   * Get About Info
   * @param {*} success success callback
   * @param {*} error error callback
   */
   getAbout(id, success, error) {
    const methodName = 'getAbout';

    httpAxios.get(`get_about/${id}`)
      .then(({ data }) => {
        logResponse(data, data.error ? `${methodName}: ERROR` : methodName);

        if (data.error) {
          error(errorHandler.processError(data.message));
        } else {
          const response = data.data;
          success(response);
        }
      })
      .catch((ex) => {
        logResponse(ex, `${methodName}: EXCEPTION`);
        error(errorHandler.processError(ex));
      });
  },

  /**
   * Get Project Items
   * @param {*} success success callback
   * @param {*} error error callback
   */
   getProjectItems(id, success, error) {
    const methodName = 'getProjectItems';

    httpAxios.get(`get_project_items/${id}`)
      .then(({ data }) => {
        logResponse(data, data.error ? `${methodName}: ERROR` : methodName);

        if (data.error) {
          error(errorHandler.processError(data.message));
        } else {
          const response = data.data;
          success(response);
        }
      })
      .catch((ex) => {
        logResponse(ex, `${methodName}: EXCEPTION`);
        error(errorHandler.processError(ex));
      });
  },


  setPreferedCard(uid, success, error) {
    const methodName = 'setPreferedCard';

    const options = {
      params: {
        query: `param: ${uid}`,
      },
      paramsSerializer: (params => qs.stringify(params)),
    };

    httpAxios.get('url', options)
      .then((response) => {
        logResponse(response, response.data.errors ? `${methodName}: ERROR` : methodName);

        if (response.data.errors) {
          error(errorHandler.processError(response.data.errors));
        } else {
          const data = response.data.data.setPreferedCard;

          success(data);
        }
      })
      .catch((ex) => {
        logResponse(ex, `${methodName}: EXCEPTION`);
        error(errorHandler.processError(ex));
      });
  },
};
